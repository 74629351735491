<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row id="main1" class="maintText__row">
            <b-col cols="12" class="pb-5 side-gap">
                <h2 style="mainText__mainName--maintxtx">
                    Mit mutatsz magadról a közösségi médiában?
                </h2>
                <p class="hashText">- Így hat másokra!</p>
            </b-col>
        </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="6">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                A közösségi média nem csupán kommunikációs formaként vagy éppen közösségszervező erőként számít jelentős újdonságnak, hanem azért is, mert ennek köszönhetően a szólásszabadság egy új korszaka köszöntött be. A platform általános szabályzatától eltekintve annak működését az azt használó szereplők határozzák meg. Ez a masszív embertömeg pedig nem egy konszenzusra épül, hanem egy folyamatosan változó véleménymasszává alakul, ami itt-ott ugyan kivet magából egy trendet, kihívást, ami vírusként robog végig az interneten, de általánosságban egyedi tartalmak létrehozása a cél, legfőképpen pedig önmagunk és véleményünk közhírré tétele. </p>

                <p class="text">
                Ez a látszólag teljesen szabad és új világ is tartogat korlátokat: az emberi közösségek korlátait, melyben kiemelt szerep jut a beilleszkedésnek, az elfogadásnak és mások jóváhagyásának. Ennek természetes velejárója, hogy közösségbe érve próbáljuk moderálni magunkat (például kisebb valószínűséggel kezdünk el táncolni a fülhallgatónkban szóló zenére, vagy éppen könyvtárba érve csöndben maradunk). Nem csoda, ha a közösségi médiába is átszivárog ez: igyekszünk a legjobb arcunkat mutatni a világ felé. </p>

                <p class="text">
                De mi is ez a legjobb arc? A legvidámabb, legmenőbb vagy éppen a legtöbb baráttal, követővel rendelkező? Vajon tudunk-e egyáltalán autentikusak maradni, ha csupán pillanatokat osztunk meg az életünkből? <br><br>
                Nézzünk meg pár jelenséget arról, hogyan hat kifelé a közösségi média és a társas összehasonlítás. </p>
            </div>
            </b-col>
            <b-col cols="12" lg="6" class="order-4 order-lg-1">
              <img src="images/kettes.jpg" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
            <b-col cols="12" lg="10" class="offset-lg-1 side-gap order-lg-2 order-1">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Amilyen az adjonisten, olyan lesz a fogadjisten</b> A közösségi média nagy előnye pont a felhasználók szabadsága, és az, hogy mindenki egyénileg is befolyásolja a platform alakulását, egyszerűen csak a használatával. Ez hatványozottan igaz az aktív szereplőkre: tartalom feltöltésekor te is hozzáteszel valamit az internetes tudástárhoz, és lehetőséget adsz a többi felhasználónak értékelni azt.</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>Sok lúd disznót győz</b> A felhasználók egyéni döntései befolyásolják a közösségi médiás platformok mozgatórugóinak számító algoritmust – a műveletet, ami meghatározza, milyen tartalmak érdekelnek egyes célközönségeket. A rendszer ezeket a tartalmakat dobja fel a platformra érkezőknek, ezzel oda-vissza alakítva a közízlést. </p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Mit szól majd a falu?</b> Végül, de nem utolsósorban továbbra is fennáll a kérdés: el tudjuk viselni, ha valakinek nem tetszik, vagy éppen nem elégséges az általunk készített tartalom? Legyen szó egy saját versről, egy nyaralós képről vagy éppen egy hogyan csináld videóról, az ember mintha házhoz menne a pofonért, amit egy váratlanul felbukkanó ismeretlen a fotelből átad neki. Az ember egyszerre bátor és védtelen, hiszen ki tudja, mit gondol a másik róla, ám egy általános beszélgetéssel szemben itt ország-világ követhet nyomon egy kommentcsatározást vagy éppen egy rossz szögből lőtt kép kritikáját.</p>
                </li>
              </ul>
            </p>
        </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Mit mutatsz magadról a közösségi médiában?", 
        desc: "A közösségi média nem csupán kommunikációs formaként vagy éppen közösségszervező erőként számít jelentős újdonságnak, hanem azért is, mert ennek köszönhetően a szólásszabadság egy új korszaka köszöntött be.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Mit mutatsz magadról a közösségi médiában?",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "A közösségi média nem csupán kommunikációs formaként vagy éppen közösségszervező erőként számít jelentős újdonságnak, hanem azért is, mert ennek köszönhetően a szólásszabadság egy új korszaka köszöntött be."        
    })
  }
}
</script>